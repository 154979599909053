import React, { useContext, useEffect, useRef, useState } from 'react';

import { Button, Col, Row, List, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';
import 'moment/locale/de';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess, SendUserActions } from '../utility';
import { generateNotifierMessage, VIDEO_CALL_ACTIONS } from '../utility/NotifierMessages';
import { useBoothCommunication } from '../hooks';

if (typeof window !== 'undefined' && window.navigator && window.navigator.language === 'de') {
    moment.locale('de');
}

const EVENT_ID = process.env.GATSBY_EVENT_ID!;
const CLIENT_ID = process.env.GATSBY_CLIENT_ID!;

const strings = new LocalizedStrings({
    en: {
        slotBooked: 'Your slot has been booked successfully. It will start automatically at the mentioned time.',
        bookSlots: 'Book Video Room Slots',
        emptyText: 'No Data',
        topic: 'Topic',
        startsAt: 'Starts at',
        endsAt: 'Ends at',
        seatsFilled: 'Seats Filled',
        bookSlot: 'Book Slot',
    },
    de: {
        slotBooked: 'Ihre Guided Tour wurde erfolgreich gebucht. Diese startet automatisch zum genannten Zeitpunkt.',
        bookSlots: 'Slot buchen',
        emptyText: 'Keine Dateien vorhanden',
        topic: 'Thema',
        startsAt: 'Beginn',
        endsAt: 'Ende',
        seatsFilled: 'Plätze gefüllt',
        bookSlot: 'Buchplatz',
    },
});

export const VideoRoomScheduleModal = ({
    showVideoRoomScheduleModal,
    setShowVideoRoomScheduleModal,
    boothId,
    moduleId,
}: {
    showVideoRoomScheduleModal: boolean;
    setShowVideoRoomScheduleModal: React.Dispatch<React.SetStateAction<boolean | string>>;
    boothId: string;
    moduleId: string;
}) => {
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [rooms, setRooms] = useState<Modules.VideoRoom[]>([]);
    const user = store.get('user') as Users.User;
    const actionsChannel = `actions.${CLIENT_ID}_${EVENT_ID}_${user?._id}`;
    const {
        objects,
        fetchMessages,
        hereNow,
        time,
        addChannels,
        removeChannels,
        addListener,
        removeListener,
        publish,
    } = useBoothCommunication();

    const refreshSchedulableRooms = async () => {
        try {
            setLoading(true);
            const rooms = await apiRequester.getSchedulableRooms({
                boothId,
                moduleId,
            });
            const currentTime = new Date().getTime();
            const activeRooms = rooms.filter(room => {
                const endTime = new Date(room.endTime!).getTime();
                return currentTime < endTime;
            });
            setRooms(activeRooms);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        boothId && moduleId && refreshSchedulableRooms();
    }, [showVideoRoomScheduleModal]);

    const bookSlot = async ({ roomId }: { roomId: string }) => {
        try {
            setLoading(true);
            await apiRequester.bookSlotInSchedulableRoom({
                boothId,
                moduleId,
                roomId,
            });
            await refreshSchedulableRooms();
            await context.refreshVideoCallRequests();
            await SendUserActions.bookVideoCallSlot({
                pubnub: context.pubnub!,
                channel: actionsChannel,
                boothId: context.booth?._id!,
                moduleId,
                roomId,
            });
            await publish({
                channel: `booth-operators.${context.booth?._id!}`,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: context.booth?._id!,
                    },
                    action: VIDEO_CALL_ACTIONS.VISITOR_ACCEPTED,
                }),
            });
            handleSuccess(strings.slotBooked);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={showVideoRoomScheduleModal}
            footer={null}
            centered
            onCancel={() => setShowVideoRoomScheduleModal(false)}
            title={strings.bookSlots}
        >
            <List
                locale={{ emptyText: strings.emptyText }}
                style={{ flexGrow: 1 }}
                dataSource={rooms}
                loading={loading}
                renderItem={(item: Modules.VideoRoom) => (
                    <List.Item
                        extra={
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => bookSlot({ roomId: item._id! })}
                                loading={loading}
                            >
                                {strings.bookSlot}
                            </Button>
                        }
                    >
                        <>
                            <Row style={{ width: '100%' }}>
                                <Col xs={8}>
                                    <Typography.Text strong>{strings.topic}</Typography.Text>
                                </Col>
                                <Col xs={16}>
                                    <Typography.Text>{item?.name}</Typography.Text>
                                </Col>
                                <Col xs={8}>
                                    <Typography.Text strong>{strings.startsAt}</Typography.Text>
                                </Col>
                                <Col xs={16}>
                                    <Typography.Text>{moment(item?.startTime).format('LLL')}</Typography.Text>
                                </Col>
                                <Col xs={8}>
                                    <Typography.Text strong>{strings.endsAt}</Typography.Text>
                                </Col>
                                <Col xs={16}>
                                    <Typography.Text>{moment(item?.endTime).format('LLL')}</Typography.Text>
                                </Col>
                                <Col xs={8}>
                                    <Typography.Text strong>{strings.seatsFilled}</Typography.Text>
                                </Col>
                                <Col xs={16}>
                                    <Typography.Text>
                                        {
                                            item.participants?.filter(participant =>
                                                participant.user?.roles?.includes('visitor'),
                                            ).length
                                        }{' '}
                                        of {item.maxVisitorCount ? item.maxVisitorCount : 'Unlimited'}
                                    </Typography.Text>
                                </Col>
                            </Row>
                        </>
                    </List.Item>
                )}
            ></List>
        </Modal>
    );
};

export default VideoRoomScheduleModal;
