import React, { useContext, useEffect, useState } from 'react';

import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import { List, Input, Typography, Button } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import Modal from 'antd/lib/modal/Modal';
import { navigate } from 'gatsby-link';
import Pubnub from 'pubnub';
import LocalizedStrings from 'react-localization';

import { GlobalContext } from '../context/GlobalContextProvider';
import { useBoothCommunication } from '../hooks';

const IFRAME_NAME = 'main-event-iframe';

const strings = new LocalizedStrings({
    en: {
        title: 'Booth Suggestions',
        mannedAdditionalGreen: 'Green button: Stand is occupied.',
        mannedAdditionalRed: 'Red button: The booth is currently unoccupied.',
        visit: 'Visit',
    },
    de: {
        title: 'Ausstellerliste',
        mannedAdditionalGreen: 'Grüner Button: Stand ist besetzt.',
        mannedAdditionalRed: 'Roter Button: Stand ist aktuell nicht besetzt.',
        visit: 'Besuch',
    },
});

export const BoothSuggestions = ({
    show,
    setShow,
    booths,
}: {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    booths: Booths.Booth[];
}) => {
    const context = useContext(GlobalContext);
    const [onlineBoothIds, setOnlineBoothIds] = useState<string[]>([]);
    const { addChannels, removeChannels, addListener, removeListener, hereNow } = useBoothCommunication();

    const refreshOnlineBoothIds = async () => {
        const booths = context.booths;
        const boothIds = booths?.map(booth => booth._id);
        const channels = boothIds?.map(boothId => `booth-operators.${boothId}`);
        const hereNowResp = (await hereNow({ channels })) || { channels: [] };
        const onlineBoothChannels = hereNowResp.channels;
        const onlineBoothChannelIds = Object.keys(onlineBoothChannels);
        const onlineBoothIds = onlineBoothChannelIds.map(channelId => channelId.split('.')[1]);
        setOnlineBoothIds(onlineBoothIds);
    };

    const presenceHandler = async (presenceEvent: Pubnub.PresenceEvent) => {
        if (presenceEvent.channel.includes('booth-operators')) refreshOnlineBoothIds();
    };

    const notifierMessageListener = {
        presence: presenceHandler,
    };

    useEffect(() => {
        if (show) {
            const boothIds = booths?.map(booth => booth._id);
            const channels = boothIds?.map(boothId => `booth-operators.${boothId}-pnpres`);
            addListener(notifierMessageListener);
            addChannels(channels);
            refreshOnlineBoothIds();
            return () => {
                removeListener(notifierMessageListener);
            };
        }
    }, [context.booths, show]);

    const getStatusColor = ({ boothId }: { boothId: string }) => {
        const boothIsOnline = context.booths?.find(booth => onlineBoothIds.includes(boothId));
        if (boothIsOnline) return '#2ecc71';
        else return '#e74c3c';
    };

    const navigateToPanorama = (panoramaNameName: string) => {
        const message = `NAVIGATE_TO_PANORAMA____${JSON.stringify(panoramaNameName)}`;
        (document?.getElementById(IFRAME_NAME) as HTMLIFrameElement)?.contentWindow?.postMessage(message, '*');
    };

    return (
        <Modal
            visible={show}
            footer={null}
            centered
            onCancel={() => setShow(false)}
            title={
                <>
                    <Typography.Text>{strings.title}</Typography.Text>
                    <br />
                    <Typography.Text type="secondary">
                        {strings.mannedAdditionalGreen} <br />
                        {strings.mannedAdditionalRed} <br />
                    </Typography.Text>
                </>
            }
            destroyOnClose={true}
        >
            <List
                itemLayout="horizontal"
                dataSource={booths.sort((booth1, booth2) => {
                    if (!booth1.name || !booth2.name) return 0;
                    else if (booth1?.name < booth2?.name) return -1;
                    else if (booth1?.name > booth2.name) return 1;
                    else return 0;
                })}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={
                                <Avatar style={{ backgroundColor: getStatusColor({ boothId: item._id! }) }}>
                                    {item.name![0]}
                                </Avatar>
                            }
                            title={item.name}
                            description={item.description}
                        />
                        {item.panoramaName && (
                            <Button
                                type="primary"
                                size="small"
                                icon={<RightOutlined />}
                                onClick={() => {
                                    navigateToPanorama(item.panoramaName!);
                                    setShow(false);
                                }}
                            >
                                {strings.visit}
                            </Button>
                        )}
                    </List.Item>
                )}
            />
        </Modal>
    );
};

export default BoothSuggestions;
