import React, { useContext, useEffect, useState } from 'react';

import { Modal, List, Button, Space, Spin } from 'antd';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';
import '../styles/productModal.css';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess, SendUserActions } from '../utility';

const EVENT_ID = process.env.GATSBY_EVENT_ID!;
const CLIENT_ID = process.env.GATSBY_CLIENT_ID!;

const strings = new LocalizedStrings({
    en: {
        fileDownloads: 'File Downloads',
        download: 'Download',
        preview: 'Preview',
        addToBasket: 'Add to Basket',
    },
    de: {
        fileDownloads: 'Datei-Downloads',
        download: 'Download',
        preview: 'Vorschau',
        addToBasket: 'Zur Dateisammlung hinzufügen',
    },
});

export const ProductModal = ({
    moduleId,
    productId,
    toggleProductView,
}: {
    moduleId?: string;
    productId?: string;
    toggleProductView: ({ productId, moduleId }: { productId?: string; moduleId?: string }) => void;
}) => {
    const pubnub = usePubNub();
    const context = useContext(GlobalContext);
    const [product, setProduct] = useState<Products.Product>();
    const user = store.get('user') as Users.User;
    const actionsChannel = `actions.${CLIENT_ID}_${EVENT_ID}_${user?._id}`;
    const [imageLoading, setImageLoading] = useState(true);

    useEffect(() => {
        productId &&
            moduleId &&
            apiRequester.getProducts({ boothId: context.booth?._id!, moduleId }).then(products => {
                const product = products.find(product => product._id === productId);
                setProduct(product);
            });
        setImageLoading(true);
    }, [moduleId, productId]);

    const AddToBasketButton = ({ item }: { item: Modules.File }) => {
        if (moduleId && productId) {
            const [loading, setLoading] = useState(false);
            const addItemToBasket = async (itemId: string) => {
                apiRequester
                    .addItemToDownloadBasket({
                        fileId: itemId,
                        boothId: context.booth?._id!,
                        moduleId: moduleId,
                    })
                    .then(() => handleSuccess('Item has been added to your download basket'))
                    .catch(handleError)
                    .finally(() => setLoading(false));
            };
            return (
                <Button type="primary" onClick={() => addItemToBasket(item._id!)} loading={loading}>
                    {strings.addToBasket}
                </Button>
            );
        } else return <></>;
    };

    const triggerVisitorAction = async ({ file, moduleId }: { file: Modules.File; moduleId: string }) => {
        const fileId = file._id!;
        await SendUserActions.downloadFile({
            pubnub,
            channel: actionsChannel,
            boothId: context.booth?._id!,
            moduleId,
            fileId,
        });
        return true;
    };

    return (
        <Modal
            destroyOnClose={true}
            visible={moduleId && productId ? true : false}
            onCancel={() => {
                toggleProductView({ productId: undefined, moduleId: undefined });
                setProduct(undefined);
            }}
            footer={null}
            title={product?.name?.split('.')[0]}
            centered={true}
            style={{ minWidth: '90vw', paddingBottom: 0 }}
            bodyStyle={{ padding: 0 }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                    style={{
                        width: '50%',
                        height: '75vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '10px',
                    }}
                >
                    {product?.imageType === 'DIRECT_UPLOAD' && product.imageLink?.link && (
                        <Spin
                            spinning={imageLoading}
                            style={{ height: '100%' }}
                            wrapperClassName="product-spinner-wrapper"
                        >
                            <img
                                id={productId}
                                src={productId ? product?.imageLink?.link : undefined}
                                style={{
                                    maxWidth: '95%',
                                    marginRight: '1.5rem',
                                    marginBottom: '1.5rem',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                }}
                                onLoad={() => setImageLoading(false)}
                            />
                        </Spin>
                    )}

                    {product?.imageType === 'IFRAME' && product.imageLink?.link && (
                        <iframe
                            src={productId ? `${product?.imageLink?.link}#toolbar=0` : undefined}
                            style={{ width: '90%', height: '100%', border: 0, marginBottom: '1.5rem' }}
                        />
                    )}

                    {!product?.imageLink?.link && (
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spin spinning={true} />
                        </div>
                    )}

                    {/* <img src={product?.thumbnail?.link} style={{ width: '100%', marginBottom: '10px' }} /> */}
                </div>
                {product && product.document?.link ? (
                    <>
                        <div
                            style={{
                                width: '50%',
                                height: '75vh',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '10px',
                            }}
                        >
                            {product.document?.link?.split('?')[0].split('.').slice(-1)[0] === 'pdf' ? (
                                <iframe
                                    src={`${product.document?.link}#toolbar=0`}
                                    style={{ border: 0, height: '100%', width: '100%', flexGrow: 1 }}
                                />
                            ) : ['doc', 'ppt', 'docx', 'pptx'].includes(
                                  product.document?.link?.split('.').slice(-1)[0]!,
                              ) ? (
                                <iframe
                                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${product.document?.link}`}
                                    style={{ border: 0, height: '100%', width: '100%', flexGrow: 1 }}
                                />
                            ) : (
                                <img src={product.document?.link} style={{ width: '100%', flexGrow: 1 }} />
                            )}
                            {product && product.document?.link && (
                                <Space key="file-download-buttons" style={{ marginTop: '1.5rem' }}>
                                    <AddToBasketButton item={product.document} />
                                </Space>
                            )}
                        </div>
                    </>
                ) : (
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spin spinning={true} />
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default ProductModal;
