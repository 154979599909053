import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { SendOutlined, WechatOutlined } from '@ant-design/icons';
import { Modal, Avatar, Typography, Input, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { default as Pubnub, default as PubNub } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import { animateScroll } from 'react-scroll';
import styled from 'styled-components';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError } from '../utility';
import { CHAT_ACTIONS, generateNotifierMessage } from '../utility/NotifierMessages';
import { useBoothCommunication } from '../hooks';

const strings = new LocalizedStrings({
    en: {
        boothChat: 'Booth Chat',
        type: 'Type your message',
    },
    de: {
        boothChat: 'Chat',
        type: 'Tippen Sie Ihre Nachricht',
    },
});
const { Text } = Typography;
const { TextArea } = Input;
const PRIMARY_COLOR = process.env.GATSBY_PRIMARY_COLOR!;
const GLOBAL_CHAT_BOOTH_ID = process.env.GATSBY_GLOBAL_CHAT_BOOTH_ID;
const GLOBAL_CHAT_MODULE_ID = process.env.GATSBY_GLOBAL_CHAT_MODULE_ID;

const ChatBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
`;

const ChatBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 1.5rem;
`;

const BubbleContainer = styled.div<{ right?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: ${props => (props.right ? 'flex-end' : 'flex-start')};
    width: 100%;
`;

const PrimaryBubble = styled.div`
    background-color: ${PRIMARY_COLOR};
    color: white;
    border-radius: 4px;
    padding: 10px;
    max-width: 75%;
    margin-left: 25%;
`;

const SecondaryBubble = styled.div`
    background-color: white;
    border-radius: 4px;
    padding: 10px;
    max-width: 75%;
`;

const Message = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
`;

const AvatarContainer = styled.div<{ right?: boolean }>`
    position: relative;
    flex-shrink: 0;
    margin-right: ${props => (props.right ? undefined : '12px')};
    margin-left: ${props => (props.right ? '12px' : undefined)};
`;

const TitleContainer = styled.div<{ right?: boolean }>`
    display: flex;
    justify-content: ${props => (props.right ? 'flex-end' : undefined)};
`;

const UserName = styled(Text)`
    padding-right: 8px;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
`;

const Time = styled(Text)`
    padding-right: 8px;
    font-size: 12px;
    line-height: 18px;
    color: #ccc;
`;

const SendContainer = styled.div`
    display: flex;
    align-items: center;
`;

const SendIcon = styled(SendOutlined)`
    font-size: 24px;
    margin-left: 12px;
    margin-right: 0;
    padding-right: 0;
    color: ${PRIMARY_COLOR};
`;

const SecondaryMessage = ({ message, user }: { message: Modules.ChatMessage; user: Users.User }) => {
    const time = new Date(parseInt(message.timetoken.toString()) / 10000);
    return (
        <Message>
            <AvatarContainer>
                <Avatar>{user?.firstName ? user.firstName[0] : 'U'}</Avatar>
            </AvatarContainer>
            <BubbleContainer>
                <TitleContainer>
                    <UserName>
                        {user?.firstName ? user.firstName : ''} {user?.lastName ? user.lastName : ''}
                    </UserName>
                    <Time>{moment(time).fromNow()}</Time>
                </TitleContainer>
                <SecondaryBubble>{message.message}</SecondaryBubble>
            </BubbleContainer>
        </Message>
    );
};

const PrimaryMessage = ({ message, user }: { message: Modules.ChatMessage; user: Users.User }) => {
    const time = new Date(parseInt(message.timetoken.toString()) / 10000);
    return (
        <Message>
            <BubbleContainer right>
                <TitleContainer right>
                    <Time>{moment(new Date(time)).fromNow()}</Time>
                    <UserName>
                        {user?.firstName ? user.firstName : ''} {user?.lastName ? user.lastName : ''}
                    </UserName>
                </TitleContainer>
                <PrimaryBubble>{message.message}</PrimaryBubble>
            </BubbleContainer>
            <AvatarContainer right>
                <Avatar>{user?.firstName ? user.firstName[0] : 'U'}</Avatar>
            </AvatarContainer>
        </Message>
    );
};

export const SiemensChatModal = ({
    boothId = GLOBAL_CHAT_BOOTH_ID!,
    moduleId = GLOBAL_CHAT_MODULE_ID!,
}: {
    boothId?: string;
    moduleId?: string;
}) => {
    const context = useContext(GlobalContext);
    const [messages, setMessages] = useState<
        { message: string; timetoken: number | string; uuid?: string; channel?: string }[]
    >([]);
    const [input, setInput] = useState('');
    const [operatorsOnline, setOperatorsOnline] = useState(false);
    const [showChatModal, setShowChatModal] = useState(false);
    const [chatId, setChatId] = useState<string>();
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const {
        objects,
        fetchMessages,
        hereNow,
        time,
        addChannels,
        removeChannels,
        addListener,
        removeListener,
        publish,
    } = useBoothCommunication();

    const refreshUserList = async (userIds: string[]) => {
        const existingIds = Object.keys(context.userList);
        const newIds = userIds.filter(userId => !existingIds.includes(userId));
        const newUserList: { [id: string]: Users.User } = {};

        if (newIds && newIds.length) {
            let filter = ``;
            newIds.map((id, index) => {
                filter += `id=="${id}" ${index < newIds.length - 1 ? '||' : ''}`;
            });

            const allUsers = await objects.getAllUUIDMetadata({
                filter: filter,
                include: { customFields: true, totalCount: true },
            });
            allUsers.data.map(userMeta => {
                const userId = userMeta.id;
                newUserList[userId] = {
                    _id: userId,
                    firstName: userMeta?.name || undefined,
                    roles: [userMeta?.custom?.role ? (userMeta?.custom?.role as Users.Role) : 'visitor'],
                };
            });

            const updatedUserList = JSON.parse(JSON.stringify({ ...context.userList, ...newUserList }));
            context.setUserList(existingUsers => {
                return { ...existingUsers, ...newUserList };
            });

            return updatedUserList;
        }

        return context.userList;
    };

    const fetchChatHistory = async ({ chatId }: { chatId: string }) => {
        setMessages([]);
        const response = await fetchMessages({ channels: [chatId], count: 100 });
        const fetchedMessages: Modules.ChatMessage[] = response.channels[chatId] || [];
        const chatUserIds = new Set<string>();
        fetchedMessages.map(message => {
            const { uuid } = message;
            uuid && uuid !== 'undefined' && chatUserIds.add(uuid);
        });
        await refreshUserList([...chatUserIds]);
        setMessages(fetchedMessages);
        return fetchedMessages;
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef?.current!?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    };

    const messageListener = async (messageEvent: Pubnub.MessageEvent) => {
        const { publisher: publisherUserId } = messageEvent;
        console.log({ publisherUserId });
        await refreshUserList([publisherUserId]);
        if (messageEvent.message && typeof messageEvent.message === 'string') {
            setMessages(messages => [
                ...messages,
                {
                    message: messageEvent.message,
                    timetoken: messageEvent.timetoken,
                    uuid: messageEvent.publisher,
                },
            ]);
            scrollToBottom();
        }
    };

    const presenceHandler = async (presenceEvent: PubNub.PresenceEvent) => {
        const operatorChannel = `booth-operators.${GLOBAL_CHAT_BOOTH_ID}`;
        if (presenceEvent.channel === operatorChannel) {
            const hereNowResp = await hereNow({ channels: [operatorChannel] });
            const operators = hereNowResp.totalOccupancy;
            console.log(operators ? true : false);
            setOperatorsOnline(operators ? true : false);
        }
    };

    const listeners: PubNub.ListenerParameters = useMemo(
        () => ({
            message: messageListener,
            presence: presenceHandler,
        }),
        [chatId],
    );

    const updateLastReadTime = async ({ channelId }: { channelId: string }) => {
        const currentTime = (await time()).timetoken;
        await objects.setChannelMetadata({
            channel: channelId,
            data: {
                custom: {
                    lastVisitorMessageTime: currentTime.toString(),
                },
            },
            include: {
                customFields: true,
            },
        });
    };

    useEffect(() => {
        apiRequester
            .getChats({ boothId, moduleId })
            .then(chats => {
                if (chats && chats.length) {
                    setChatId(chats[0]._id!);
                } else throw new Error('chat_not_found');
            })
            .catch(err => {
                if (err.message === 'chat_not_found') return apiRequester.createChat({ boothId, moduleId });
                else handleError(err);
            })
            .then(res => apiRequester.getChats({ boothId, moduleId }))
            .then(chats => {
                if (chats && chats.length) {
                    setChatId(chats[0]._id!);
                } else throw new Error('chat_not_found');
            })
            .catch(err => {
                handleError(err);
            });
    }, [boothId, moduleId]);

    useEffect(() => {
        if (!chatId) return;

        if (chatId) {
            fetchChatHistory({ chatId })
                .then(() => scrollToBottom())
                .then(() => addListener(listeners))
                .then(() => addChannels([chatId, `booth-operators.${boothId}-pnpres`]))
                .then(async () => {
                    const operators = (await hereNow({ channels: [`booth-operators.${boothId}`] })).totalOccupancy;
                    setOperatorsOnline(operators ? true : false);
                })
                .catch(handleError);
        }

        // Unsubscribe when unmounted
        return () => {
            removeListener(listeners);
            removeChannels([chatId]);
        };
    }, [chatId]);

    useEffect(() => {
        scrollToBottom();
    }, [showChatModal]);

    const getUserFromId = (userId: string) => {
        return context.userList[userId];
    };

    const sendMessage = async () => {
        try {
            if (!input) return;
            await publish({
                channel: chatId!,
                message: input,
            });
            setInput('');
            await updateLastReadTime({ channelId: chatId! });
            await publish({
                channel: `booth-operators.${boothId}`,
                message: generateNotifierMessage.chat({
                    booth: {
                        id: boothId,
                    },
                    action: CHAT_ACTIONS.NEW_MESSAGE,
                    sender: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName,
                        lastName: context.user?.lastName,
                    },
                }),
            });
        } catch (err) {
            handleError(err);
        }
    };

    const keyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            sendMessage();
        }
    };

    return (
        <>
            <Modal
                visible={chatId && showChatModal ? true : false}
                footer={null}
                title={
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        {operatorsOnline ? <Tag color="#87d068">ONLINE</Tag> : <Tag color="#f50">OFFLINE</Tag>}
                        <Typography.Title level={5}>{strings.boothChat}</Typography.Title>
                    </div>
                }
                onCancel={() => setShowChatModal(false)}
                bodyStyle={{ height: '60vh', backgroundColor: '#f6f6fc' }}
            >
                <ChatBoxContainer>
                    <ChatBox id="chat-box">
                        <>
                            {messages.map((messageEvent, messageIndex) => {
                                const messageSenderUserId = messageEvent.uuid;
                                const messageBelongsToLoggedInUser = messageSenderUserId === context.user?._id!;
                                const user = getUserFromId(messageEvent.uuid!);
                                return messageBelongsToLoggedInUser ? (
                                    <PrimaryMessage
                                        key={`message-${messageIndex}`}
                                        message={messageEvent as Modules.ChatMessage}
                                        user={user}
                                    />
                                ) : (
                                    <SecondaryMessage
                                        key={`message-${messageIndex}`}
                                        message={messageEvent as Modules.ChatMessage}
                                        user={user}
                                    />
                                );
                            })}
                            <div ref={messagesEndRef} />
                        </>
                    </ChatBox>

                    <SendContainer>
                        <TextArea
                            placeholder={strings.type}
                            value={input}
                            onChange={e => setInput(e.target.value)}
                            onKeyDown={keyPress}
                        />
                        <SendIcon onClick={sendMessage} />
                    </SendContainer>
                </ChatBoxContainer>
            </Modal>

            {operatorsOnline && (
                <Tooltip title="Chat with help desk" color="#01A4B4" placement="left">
                    <div
                        style={{
                            right: 0,
                            top: 'calc(50vh - 70px)',
                            backgroundColor: 'white',
                            position: 'absolute',
                            padding: '10px',
                            borderTopLeftRadius: '5px',
                            borderBottomLeftRadius: '5px',
                            zIndex: 2000,
                            cursor: 'pointer',
                        }}
                        onClick={() => setShowChatModal(true)}
                    >
                        <WechatOutlined style={{ fontSize: '40px' }} />
                    </div>
                </Tooltip>
            )}
        </>
    );
};

export default SiemensChatModal;
