import React, { useContext, useState } from 'react';

import { VideoCameraAddOutlined, CloseOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Modal, Row, Space, Avatar, Spin } from 'antd';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess, SendUserActions } from '../utility';
import { generateNotifierMessage, VIDEO_CALL_ACTIONS } from '../utility/NotifierMessages';
import { useBoothCommunication } from '../hooks';

const EVENT_ID = process.env.GATSBY_EVENT_ID!;
const CLIENT_ID = process.env.GATSBY_CLIENT_ID!;

const strings = new LocalizedStrings({
    en: {
        operator: 'Operator',
        callStartOnOperatorJoin:
            'The video call will start once the operator has joined. Please do not leave the booth.',
        requestingCall: '{0} from the booth is requesting a video call',
    },
    de: {
        operator: 'Moderator',
        callStartOnOperatorJoin:
            'Dieser Videoanruf beginnt, sobald der Moderator anwesend ist. Bitte bleiben Sie auf dem Stand. ',
        requestingCall: '{0} von diesem Stand möchte einen Videoanruf starten',
    },
});

export const VideoCallRequestModal = ({
    activeVideoCallRequest,
    setActiveVideoCallRequest,
}: {
    activeVideoCallRequest?: Modules.VideoCallRequest;
    setActiveVideoCallRequest: React.Dispatch<React.SetStateAction<Modules.VideoCallRequest | undefined>>;
}) => {
    const context = useContext(GlobalContext);
    const user = store.get('user') as Users.User;
    const [loading, setLoading] = useState<boolean | string>(false);
    const getVideoCallRequesterName = (user: Users.User | undefined | string) => {
        if (!user || typeof user === 'string') return strings.operator;
        else return user.firstName + ' ' + user.lastName;
    };
    const actionsChannel = `actions.${CLIENT_ID}_${EVENT_ID}_${user?._id}`;
    const {
        objects,
        fetchMessages,
        hereNow,
        time,
        addChannels,
        removeChannels,
        addListener,
        removeListener,
        publish,
    } = useBoothCommunication();

    const acceptActiveVideoCallRequest = async () => {
        try {
            setLoading('accept');
            await apiRequester.acceptVideoCall({
                boothId: context.booth?._id!,
                moduleId: activeVideoCallRequest?.module?._id!,
                requestId: activeVideoCallRequest?._id!,
            });
            await SendUserActions.acceptVideoCallRequest({
                pubnub: context.pubnub!,
                channel: actionsChannel,
                boothId: context.booth?._id!,
                moduleId: activeVideoCallRequest?.module?._id!,
                requestId: activeVideoCallRequest?._id!,
            });
            await publish({
                channel: `booth-operators.${context.booth?._id!}`,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: context.booth?._id!,
                    },
                    requester:
                        typeof activeVideoCallRequest?.operator === 'string'
                            ? { id: activeVideoCallRequest?.operator }
                            : {
                                  id: activeVideoCallRequest?.operator?._id!,
                                  firstName: activeVideoCallRequest?.operator?.firstName,
                                  lastName: activeVideoCallRequest?.operator?.lastName,
                              },
                    respondent: { id: user._id!, firstName: user.firstName, lastName: user.lastName },
                    action: VIDEO_CALL_ACTIONS.VISITOR_ACCEPTED,
                }),
            });
            setLoading(false);
            context.refreshVideoCallRequests();
            handleSuccess(strings.callStartOnOperatorJoin!);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    const rejectActiveVideoCallRequest = async () => {
        try {
            setLoading('reject');
            await apiRequester.rejectVideoCall({
                boothId: context.booth?._id!,
                moduleId: activeVideoCallRequest?.module?._id!,
                requestId: activeVideoCallRequest?._id!,
            });
            await SendUserActions.rejectVideoCallRequest({
                pubnub: context.pubnub!,
                channel: actionsChannel,
                boothId: context.booth?._id!,
                moduleId: activeVideoCallRequest?.module?._id!,
                requestId: activeVideoCallRequest?._id!,
            });
            await publish({
                channel: `booth-operators.${context.booth?._id!}`,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: context.booth?._id!,
                    },
                    requester:
                        typeof activeVideoCallRequest?.requester === 'string'
                            ? { id: activeVideoCallRequest?.requester }
                            : {
                                  id: activeVideoCallRequest?.operator?._id!,
                                  firstName: activeVideoCallRequest?.operator?.firstName,
                                  lastName: activeVideoCallRequest?.operator?.lastName,
                              },
                    respondent: { id: user._id!, firstName: user.firstName, lastName: user.lastName },
                    action: VIDEO_CALL_ACTIONS.VISITOR_REJECTED,
                }),
            });
            setLoading(false);
            setActiveVideoCallRequest(undefined);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    return (
        <Modal visible={activeVideoCallRequest ? true : false} closable={false} footer={null} centered={true}>
            <Row justify="center" style={{ marginBottom: '1.5rem' }}>
                <Avatar size={128}>
                    <UserOutlined style={{ fontSize: '128px' }} />
                </Avatar>
            </Row>
            <Row justify="center" style={{ marginBottom: '1.5rem' }}>
                {strings.formatString(
                    strings.requestingCall!,
                    getVideoCallRequesterName(activeVideoCallRequest?.operator) as string,
                )}
            </Row>
            <Row justify="center">
                <Space>
                    <Button
                        shape="circle"
                        style={{ backgroundColor: '#e74c3c', color: 'white', border: 0 }}
                        size="large"
                        onClick={rejectActiveVideoCallRequest}
                        disabled={loading && loading === 'reject' ? true : false}
                    >
                        {loading && loading === 'reject' ? <LoadingOutlined spin={true} /> : <CloseOutlined />}
                    </Button>
                    <Button
                        shape="circle"
                        style={{ backgroundColor: '#27ae60', color: 'white', border: 0 }}
                        size="large"
                        onClick={acceptActiveVideoCallRequest}
                        disabled={loading && loading === 'accept' ? true : false}
                    >
                        {loading && loading === 'accept' ? <LoadingOutlined spin={true} /> : <VideoCameraAddOutlined />}
                    </Button>
                </Space>
            </Row>
        </Modal>
    );
};

export default VideoCallRequestModal;
