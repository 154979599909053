import React, { useContext, useEffect, useState } from 'react';

import { CloseCircleFilled, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row, Typography, Modal, Button, Form, Input, Select } from 'antd';
import LocalizedStrings from 'react-localization';
import styled from 'styled-components';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';
import './SiemensContactForm.css';

const { Option } = Select;

const strings = new LocalizedStrings({
    en: {
        responseSubmitted: 'Your response has been recorded.',
    },
    de: {},
});

const StyledSelect = styled(Select)`
    background: transparent;
    border-radius: 20px;
    text-align: center;
    color: #01a4b4;
    border-color: #01a4b4;
`;

const StyledInput = styled(Input)`
    background: transparent;
    border-radius: 20px;
    text-align: center;
    color: #01a4b4 !important;
    border-color: #01a4b4;
    border-width: 2px;
`;

const topics = [
    {
        topic: 'IoT-solutions-for-a-resilient-workplace – Back to work',
        subTopics: [],
    },
    {
        topic: 'Smart Buildings',
        subTopics: [
            {
                topic: 'BMS Software as a service',
            },
            {
                topic: 'Building twin ecosystem',
            },
            {
                topic: 'Digital workplace application',
            },
            {
                topic: 'IoT network for building',
            },
            {
                topic: 'Smart maintenance',
            },
        ],
    },
    {
        topic: 'Smart Utility',
        subTopics: [
            {
                topic: 'Assisted operations',
            },
            {
                topic: 'Future oriented substation',
            },
            {
                topic: 'Grid planning and consulting',
            },
            {
                topic: 'Power quality analytics',
            },
            { topic: 'Water smart grid' },
        ],
    },
    {
        topic: 'Integrated command & control',
        subTopics: [],
    },
    {
        topic: 'Smart Security',
        subTopics: [
            {
                topic: 'Smart security',
            },
            {
                topic: 'cyber security',
            },
        ],
    },
    {
        topic: 'Grid Edge',
        subTopics: [
            {
                topic: 'Ecar Operations management',
            },
            {
                topic: 'Integration platform for decentralized energy sources',
            },
            {
                topic: 'Micro grid management',
            },
            {
                topic: 'Smart metering infrastructure',
            },
        ],
    },
    {
        topic: 'Smart Environment',
        subTopics: [
            {
                topic: 'Artificial intelligence in traffic management',
            },
            {
                topic: 'Clean air for all',
            },
            {
                topic: 'Energy and performance services',
            },
            {
                topic: 'Smart street lighting',
            },
        ],
    },
];

export const SiemensContactForm = ({ visible, onCancel }: { visible?: string; onCancel: () => void }) => {
    const [subTopics, setSubTopics] = useState<[string[]] | []>([]);
    const [requirements, setRequirements] = useState<[string[]] | []>([]);
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [form] = Form.useForm();
    const [questions, setQuestions] = useState<Modules.SiemensFormQuestion[]>([]);
    const [initialFormValue, setInitialFormValue] = useState<{
        answers: [{ topic?: string; subTopic?: string; requirements?: string }];
    }>();

    const getQuestions = async () => {
        try {
            setLoading(true);
            if (visible) {
                const questions = await apiRequester.getSiemensFormQuestions({ moduleId: visible! });
                setQuestions(questions);
                if (questions.length) {
                    const firstQuestion = questions[0];
                    const initialValue: {
                        answers: [{ topic?: string; subTopic?: string; requirements?: string }];
                    } = {
                        answers: [
                            {
                                topic: firstQuestion.topic,
                                subTopic: firstQuestion.subTopics.length ? firstQuestion.subTopics[0] : undefined,
                                requirements: firstQuestion.requirements.length
                                    ? firstQuestion.requirements[0]
                                    : undefined,
                            },
                        ],
                    };
                    setInitialFormValue(initialValue);
                }
            }
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getQuestions();
    }, [visible]);

    const onValuesChange = (changedValues: any, values: any) => {
        const formValues = form.getFieldsValue();
        const answers = formValues.answers;

        // When topic is changed, reset sub topic and requirements
        if (changedValues && changedValues.answers && changedValues.answers.length) {
            changedValues.answers.map((answer: any, answerIndex: number) => {
                if (answer && answer.topic && !answer.subTopic && !answer.requirements && !answer.comments) {
                    formValues.answers[answerIndex].subTopic = undefined;
                    formValues.answers[answerIndex].requirements = undefined;
                }
            });
        }

        const subTopics = {
            ...answers.map((answer: { topic?: string }) => {
                const receivedTopic = answer && answer.topic ? answer.topic : undefined;
                const topicObj = questions.find(question => question.topic === receivedTopic);
                const subTopics = topicObj?.subTopics || [];
                return subTopics;
            }),
        };

        const requirements = {
            ...answers.map((answer: { topic?: string }) => {
                const receivedTopic = answer && answer.topic ? answer.topic : undefined;
                const topicObj = questions.find(question => question.topic === receivedTopic);
                const requirements = topicObj?.requirements || [];
                return requirements;
            }),
        };
        setSubTopics(subTopics);
        setRequirements(requirements);
        form.setFieldsValue(answers);
    };

    const submitForm = async () => {
        try {
            setLoading(true);
            const answers = form.getFieldValue('answers');
            if (!answers || !answers.length) return handleError(new Error('Please fill the form'));
            const responses = answers.map(
                (
                    answer: { topic?: string; subTopic?: string; requirements?: string; comments?: string },
                    index: number,
                ) => ({
                    question: index.toString(),
                    questionId: index.toString(),
                    // @ignore typescript-eslint/camelcase
                    answer_obj: answer,
                }),
            );
            await apiRequester.submitExtFormResponse({ moduleId: visible!, responses });
            form.resetFields();
            handleSuccess(strings.responseSubmitted!);
            setIsSubmitted(true);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={!!visible}
            onCancel={onCancel}
            width="100vw"
            centered
            bodyStyle={{
                height: '86vh',
                border: '2px solid #01A4B4',
                borderRadius: '5px',
                backgroundImage: 'url("siemens-background.jpg")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            }}
            mask={false}
            maskClosable={false}
            keyboard={false}
            footer={null}
            closable={true}
            style={{
                padding: 0,
                maxWidth: '100vw',
                height: '100%',
            }}
            closeIcon={<CloseCircleFilled style={{ fontSize: '1.5rem', color: '#01A4B4' }} />}
            destroyOnClose={true}
            className="siemens-contact-form"
        >
            <Row gutter={[24, 24]} style={{ height: '100%', padding: '3rem' }}>
                <Col xs={24} style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ borderLeft: '4px solid #01A4B4', paddingLeft: '12px' }}>
                        {isSubmitted && (
                            <Typography.Text style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>
                                Thank you for your query
                            </Typography.Text>
                        )}
                        {!isSubmitted && (
                            <>
                                <Typography.Text style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>
                                    Hello,
                                </Typography.Text>
                                <br />
                                <Typography.Text style={{ color: 'white' }}>
                                    what are you interested in?
                                </Typography.Text>
                            </>
                        )}
                    </div>
                    <div>
                        {isSubmitted && (
                            <Button
                                style={{
                                    backgroundColor: '#01A4B4',
                                    border: 0,
                                    borderRadius: '20px',
                                    marginTop: '1.5rem',
                                }}
                                type="primary"
                                onClick={() => {
                                    onCancel();
                                    setIsSubmitted(false);
                                }}
                            >
                                Home
                            </Button>
                        )}
                    </div>
                </Col>
                {!isSubmitted && (
                    <Col
                        xs={24}
                        style={{
                            height: '100%',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {questions && questions.length && (
                            <Form
                                onFinish={submitForm}
                                autoComplete="off"
                                form={form}
                                onValuesChange={onValuesChange}
                                initialValues={{
                                    answers: [
                                        {
                                            topic: undefined,
                                            subTopic: undefined,
                                            requirements: undefined,
                                        },
                                    ],
                                }}
                            >
                                <Form.List name="answers">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                                <div
                                                    key={key}
                                                    style={{
                                                        display: 'flex',
                                                        marginBottom: 8,
                                                        width: '100%',
                                                        alignItems: 'baseline',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <span style={{ width: '23%' }}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'topic']}
                                                            fieldKey={[fieldKey, 'topic']}
                                                            rules={[{ required: true, message: 'Select Topic' }]}
                                                        >
                                                            <StyledSelect
                                                                placeholder="Select Topic"
                                                                dropdownStyle={{
                                                                    backgroundColor: '#01A4B4',
                                                                    color: 'white',
                                                                }}
                                                                size="large"
                                                            >
                                                                {questions &&
                                                                    questions.length &&
                                                                    questions.map((question, questionIndex) => (
                                                                        <Option
                                                                            key={`option-${index}-topic-${questionIndex}`}
                                                                            style={{
                                                                                backgroundColor: '#01a4b4',
                                                                                color: 'white',
                                                                            }}
                                                                            value={question.topic!}
                                                                        >
                                                                            {question.topic}
                                                                        </Option>
                                                                    ))}
                                                            </StyledSelect>
                                                        </Form.Item>
                                                    </span>
                                                    <span style={{ width: '23%' }}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'subTopic']}
                                                            fieldKey={[fieldKey, 'subTopic']}
                                                            rules={[{ required: false, message: '' }]}
                                                        >
                                                            <StyledSelect
                                                                dropdownStyle={{
                                                                    backgroundColor: '#01A4B4',
                                                                    color: 'white',
                                                                }}
                                                                placeholder="Select Sub-Topic"
                                                                size="large"
                                                            >
                                                                {subTopics &&
                                                                    subTopics[index] &&
                                                                    subTopics[index].length &&
                                                                    subTopics[index].map((subTopic, topicIndex) => (
                                                                        <Option
                                                                            key={`option-${index}-subtopic-${topicIndex}`}
                                                                            style={{
                                                                                backgroundColor: '#01a4b4',
                                                                                color: 'white',
                                                                            }}
                                                                            value={subTopic}
                                                                        >
                                                                            {subTopic}
                                                                        </Option>
                                                                    ))}
                                                            </StyledSelect>
                                                        </Form.Item>
                                                    </span>
                                                    <span style={{ width: '23%' }}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'requirements']}
                                                            fieldKey={[fieldKey, 'requirements']}
                                                            rules={[{ required: false, message: '' }]}
                                                        >
                                                            <StyledSelect
                                                                dropdownStyle={{
                                                                    backgroundColor: '#01A4B4',
                                                                    color: 'white',
                                                                }}
                                                                placeholder="Select Requirements"
                                                                size="large"
                                                            >
                                                                {requirements &&
                                                                    requirements[index] &&
                                                                    requirements[index].length &&
                                                                    requirements[index].map(
                                                                        (requirement, requirementIndex) => (
                                                                            <Option
                                                                                key={`option-${index}-requirement-${requirementIndex}`}
                                                                                style={{
                                                                                    backgroundColor: '#01a4b4',
                                                                                    color: 'white',
                                                                                }}
                                                                                value={requirement}
                                                                            >
                                                                                {requirement}
                                                                            </Option>
                                                                        ),
                                                                    )}
                                                            </StyledSelect>
                                                        </Form.Item>
                                                    </span>
                                                    <span style={{ width: '23%' }}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'comments']}
                                                            fieldKey={[fieldKey, 'comments']}
                                                        >
                                                            <StyledInput
                                                                value={undefined}
                                                                placeholder="Add Comments"
                                                                size="large"
                                                            />
                                                        </Form.Item>
                                                    </span>
                                                    <span>
                                                        <MinusCircleOutlined
                                                            style={{ color: 'white' }}
                                                            onClick={() => remove(name)}
                                                        />
                                                    </span>
                                                </div>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    style={{
                                                        background: 'transparent',
                                                        borderRadius: '20px',
                                                        textAlign: 'center',
                                                        color: '#01A4B4',
                                                        borderColor: '#01A4B4',
                                                    }}
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add field
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                                <Form.Item>
                                    <Button
                                        style={{ backgroundColor: '#01A4B4', border: 0, borderRadius: '20px' }}
                                        type="primary"
                                        htmlType="submit"
                                        loading={loading}
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </Col>
                )}
            </Row>
        </Modal>
    );
};

export default SiemensContactForm;
