import React from 'react';

import {
    CloseCircleTwoTone,
    FacebookFilled,
    InstagramFilled,
    InstagramOutlined,
    LinkedinFilled,
    LinkOutlined,
    TwitterOutlined,
    YoutubeFilled,
} from '@ant-design/icons';
import { Modal } from 'antd';
import LocalizedStrings from 'react-localization';

const getIcon = (link: string) => {
    return link.includes('twitter') ? (
        <TwitterOutlined style={{ fontSize: '64px', color: '#1D9BF0' }} />
    ) : link.includes('youtube') ? (
        <YoutubeFilled style={{ fontSize: '64px', color: '#c4302b' }} />
    ) : link.includes('facebook') ? (
        <FacebookFilled style={{ fontSize: '64px', color: '#3b5998' }} />
    ) : link.includes('linkedin') ? (
        <LinkedinFilled style={{ fontSize: '64px', color: '#0A66C2' }} />
    ) : link.includes('instagram') ? (
        <InstagramOutlined
            style={{
                fontSize: '64px',
                color: 'white',
                background:
                    'radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)',
                borderRadius: '4px',
            }}
        />
    ) : (
        <LinkOutlined style={{ fontSize: '64px', color: 'gray' }} />
    );
};

const strings = new LocalizedStrings({
    en: {
        socialLinks: 'Social Links',
    },
    de: {
        socialLinks: 'Soziale Links',
    },
});

export const PopupSocialLinks = ({
    visible,
    socialLinks,
    hidePopup,
}: {
    visible: boolean;
    socialLinks?: string[];
    hidePopup: () => void;
}) => {
    return (
        <Modal
            visible={visible}
            onCancel={hidePopup}
            title={strings.socialLinks}
            footer={null}
            closeIcon={<CloseCircleTwoTone style={{ fontSize: '32px' }} />}
            destroyOnClose={true}
            bodyStyle={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}
        >
            {socialLinks?.map(link => (
                <div key={link}>
                    <a href={link} target="_blank">
                        {getIcon(link)}
                    </a>
                </div>
            ))}
        </Modal>
    );
};

export default PopupSocialLinks;
