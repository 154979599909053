import React, { useContext, useEffect, useRef, useState } from 'react';

import { FullscreenExitOutlined, CloseOutlined } from '@ant-design/icons';
import Daily, { DailyCall } from '@daily-co/daily-js';
import { Button, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';
import styled from 'styled-components';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, SendUserActions } from '../utility';
import { generateNotifierMessage, VIDEO_CALL_ACTIONS } from '../utility/NotifierMessages';

import './VideoCallModal.css';
import { useBoothCommunication } from '../hooks';

const EVENT_ID = process.env.GATSBY_EVENT_ID!;
const CLIENT_ID = process.env.GATSBY_CLIENT_ID!;

const strings = new LocalizedStrings({
    en: {
        linkNotFound: 'Video call link not found',
        tokenNotFound: 'Token not found',
        ongoingCall: 'Ongoing video call',
        maximize: 'Maximize',
        minimize: 'Minimize',
        endCall: 'End Call',
    },
    de: {
        linkNotFound: 'Videoanruf wurde nicht gefunden',
        tokenNotFound: 'Nicht gefunden',
        ongoingCall: 'Videoanruf findet statt',
        maximize: 'Maximieren',
        minimize: 'Minimieren',
        endCall: 'Anruf beenden',
    },
});

const IframeWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
`;

const Iframe = styled.iframe`
    border: 0;
    width: 100%;
    flex-grow: 1;
`;

const IframeActionButtonWrapper = styled.div`
    text-align: center;
    margin-top: 10px;
`;

export const VideoCallModal = ({ request }: { request?: Modules.VideoCallRequest }) => {
    const [maximized, setMaximized] = useState(true);
    const context = useContext(GlobalContext);
    const videoCallFrameRef = useRef<HTMLIFrameElement>(null);
    const [loading, setLoading] = useState(false);
    let loggedInUser: Users.User = store.get('user') as Users.User;
    const actionsChannel = `actions.${CLIENT_ID}_${EVENT_ID}_${loggedInUser?._id}`;
    const { publish } = useBoothCommunication();

    const endVideoCall = async () => {
        try {
            setLoading(true);
            await apiRequester.endVideoCall({
                boothId: request?.booth?._id!,
                moduleId: request?.module?._id!,
                requestId: request?._id!,
            });
            await SendUserActions.endVideoRoom({
                pubnub: context.pubnub!,
                channel: actionsChannel,
                boothId: request?.booth?._id!,
                moduleId: request?.module?._id!,
                requestId: request?._id!,
            });
            await context.refreshVideoCallRequests();
            await publish({
                channel: `booth-operators.${request?.booth?._id!}`,
                message: generateNotifierMessage.videoRequest({
                    booth: {
                        id: request?.booth?._id!,
                    },
                    requester: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName!,
                        lastName: context.user?.lastName!,
                    },
                    action: VIDEO_CALL_ACTIONS.VISITOR_ENDED,
                }),
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    useEffect(() => {
        let callFrame: DailyCall;
        loggedInUser = store.get('user');
        if (videoCallFrameRef.current !== null && request) {
            const videoLink = request?.room?.link;
            const user = request?.room?.participants?.find(participant => participant.user?._id === context.user?._id);
            const token = user?.token;

            if (!videoLink) throw new Error(strings.linkNotFound);
            if (!token) throw new Error(strings.tokenNotFound);

            callFrame = Daily.wrap(videoCallFrameRef.current, {
                showFullscreenButton: true,
                iframeStyle: {
                    position: 'fixed',
                    top: '10%',
                    left: '10%',
                    width: '80%',
                    height: '80%',
                    zIndex: '1000',
                    border: '0',
                },
            });

            callFrame.on('left-meeting', async () => {
                callFrame.leave();
            });

            callFrame.join({ url: videoLink + '?t=' + token });

            SendUserActions.joinVideoRoom({
                pubnub: context.pubnub!,
                channel: actionsChannel,
                boothId: request?.booth?._id!,
                moduleId: request?.module?._id!,
                requestId: request?._id!,
            });
        }

        return () => {
            if (callFrame) {
                callFrame.leave();
                SendUserActions.leaveVideoRoom({
                    pubnub: context.pubnub!,
                    channel: actionsChannel,
                    boothId: request?.booth?._id!,
                    moduleId: request?.module?._id!,
                    requestId: request?._id!,
                });
            }
        };
    }, [request]);

    {
        !maximized
            ? message.info({
                  content: (
                      <>
                          {strings.ongoingCall}{' '}
                          <Button type="primary" size="small" onClick={() => setMaximized(true)}>
                              {strings.maximize}
                          </Button>
                      </>
                  ),
                  duration: 0,
                  key: 'minimized-video-call',
              })
            : message.destroy('minimized-video-call');
    }

    useEffect(() => {
        if (request) {
            console.log(`loading video call: ${request?._id}`);
            return () => console.log(`un-loading video call: ${request?._id}`);
        }
    }, [request]);

    return (
        <Modal
            className="video-call-modal"
            closable={false}
            width="100vw"
            style={{ height: '100%' }}
            visible={request && maximized}
            footer={null}
            centered
            bodyStyle={{ height: '100%', padding: '10px' }}
        >
            <IframeWrapper>
                <Iframe
                    ref={videoCallFrameRef}
                    allowFullScreen={true}
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; camera; microphone; display-capture;"
                />
                <IframeActionButtonWrapper>
                    <Button onClick={() => setMaximized(false)} loading={loading} size="small">
                        <FullscreenExitOutlined /> {strings.minimize}
                    </Button>{' '}
                    <Button onClick={endVideoCall} danger loading={loading} size="small">
                        <CloseOutlined /> {strings.endCall}
                    </Button>
                </IframeActionButtonWrapper>
            </IframeWrapper>
        </Modal>
    );
};

export default VideoCallModal;
