import React, { useContext, useEffect, useState } from 'react';

import { VideoCameraOutlined } from '@ant-design/icons';
import { default as Pubnub, default as PubNub } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, SendUserActions } from '../utility';
import { generateNotifierMessage, VIDEO_CALL_ACTIONS } from '../utility/NotifierMessages';
import { useBoothCommunication } from '../hooks';
import { Tooltip } from 'antd';

const strings = new LocalizedStrings({
    en: {
        boothChat: 'Booth Chat',
        type: 'Type your message',
    },
    de: {
        boothChat: 'Chat',
        type: 'Tippen Sie Ihre Nachricht',
    },
});

const PRIMARY_COLOR = process.env.GATSBY_PRIMARY_COLOR!;
const GATSBY_GLOBAL_VIDEO_BOOTH_ID = process.env.GATSBY_GLOBAL_VIDEO_BOOTH_ID!;
const GATSBY_GLOBAL_VIDEO_MODULE_ID = process.env.GATSBY_GLOBAL_VIDEO_MODULE_ID!;
const EVENT_ID = process.env.GATSBY_EVENT_ID!;
const CLIENT_ID = process.env.GATSBY_CLIENT_ID!;

export const SiemensVideoCallModal = () => {
    const context = useContext(GlobalContext);
    const user = store.get('user') as Users.User;
    const actionsChannel = `actions.${CLIENT_ID}_${EVENT_ID}_${user?._id}`;
    const [operatorsOnline, setOperatorsOnline] = useState(false);
    const {
        objects,
        fetchMessages,
        hereNow,
        time,
        addChannels,
        removeChannels,
        addListener,
        removeListener,
        publish,
    } = useBoothCommunication();

    const presenceHandler = async (presenceEvent: PubNub.PresenceEvent) => {
        console.log('siemens video', presenceEvent);
        const operatorChannel = `booth-operators.${GATSBY_GLOBAL_VIDEO_BOOTH_ID}`;
        if (presenceEvent.channel === operatorChannel) {
            const hereNowResp = await hereNow({ channels: [operatorChannel] });
            const operators = hereNowResp.totalOccupancy;
            console.log(operators ? true : false);
            setOperatorsOnline(operators ? true : false);
        }
    };

    const listeners: PubNub.ListenerParameters = {
        presence: presenceHandler,
    };

    useEffect(() => {
        addListener(listeners);
        addChannels([`booth-operators.${GATSBY_GLOBAL_VIDEO_BOOTH_ID}-pnpres`]);
        (async () => {
            const operators = (await hereNow({ channels: [`booth-operators.${GATSBY_GLOBAL_VIDEO_BOOTH_ID}`] }))
                .totalOccupancy;
            setOperatorsOnline(operators ? true : false);
        })();

        // Unsubscribe when unmounted
        return () => {
            removeListener(listeners);
            removeChannels([`booth-operators.${GATSBY_GLOBAL_VIDEO_BOOTH_ID}-pnpres`]);
        };
    }, []);

    const requestVideoCall = async () => {
        try {
            const boothId = GATSBY_GLOBAL_VIDEO_BOOTH_ID;
            await apiRequester.requestVideoCall({
                boothId,
                moduleId: GATSBY_GLOBAL_VIDEO_MODULE_ID,
            });
            await context.refreshVideoCallRequests();
            await SendUserActions.requestVideoCall({
                pubnub: context.pubnub!,
                channel: actionsChannel,
                boothId: GATSBY_GLOBAL_VIDEO_BOOTH_ID,
                moduleId: GATSBY_GLOBAL_VIDEO_MODULE_ID,
            });
            await publish({
                channel: `booth-operators.${GATSBY_GLOBAL_VIDEO_BOOTH_ID}`,
                message: generateNotifierMessage.videoRequest({
                    action: VIDEO_CALL_ACTIONS.NEW_REQUEST_FROM_VISITOR_TO_OPERATOR,
                    requester: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName!,
                        lastName: context.user?.lastName!,
                    },
                    booth: {
                        id: GATSBY_GLOBAL_VIDEO_BOOTH_ID,
                    },
                }),
            });
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <>
            {operatorsOnline && (
                <Tooltip title="Video call with help desk" color="#01A4B4" placement="left">
                    <div
                        style={{
                            right: 0,
                            top: 'calc(50vh + 10px)',
                            backgroundColor: 'white',
                            position: 'absolute',
                            padding: '10px',
                            borderTopLeftRadius: '5px',
                            borderBottomLeftRadius: '5px',
                            zIndex: 2000,
                            cursor: 'pointer',
                        }}
                        onClick={requestVideoCall}
                    >
                        <VideoCameraOutlined style={{ fontSize: '40px' }} />
                    </div>
                </Tooltip>
            )}
        </>
    );
};

export default SiemensVideoCallModal;
