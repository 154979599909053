import React, { useContext, useEffect, useState } from 'react';

import { Modal, List, Button, Space, Tooltip } from 'antd';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess, SendUserActions } from '../utility';
import {
    CloseOutlined,
    CloseSquareOutlined,
    DownloadOutlined,
    DropboxOutlined,
    EyeOutlined,
    FolderAddOutlined,
} from '@ant-design/icons';

const EVENT_ID = process.env.GATSBY_EVENT_ID!;
const CLIENT_ID = process.env.GATSBY_CLIENT_ID!;

const GATSBY_CLIENT_NAME = process.env.GATSBY_CLIENT_NAME || '';
const clientIsViatris = ['viatris', 'viatris-hulio'].includes(GATSBY_CLIENT_NAME);
const SHOW_ADD_TO_BASKET_BTN = !clientIsViatris;

const strings = new LocalizedStrings({
    en: {
        fileDownloads: 'File Downloads',
        download: 'Download',
        preview: 'Preview',
        addToBasket: 'Add to Basket',
    },
    de: {
        fileDownloads: 'Datei-Downloads',
        download: 'Download',
        preview: 'Vorschau',
        addToBasket: 'Zur Dateisammlung hinzufügen',
    },
});

const fileDownloadsTitle = clientIsViatris ? strings.download : strings.fileDownloads;

export const FileDownloadsModal = ({
    moduleId,
    setModuleId,
    skipFileDownloadList,
    setSkipFileDownloadList,
}: {
    moduleId?: string;
    setModuleId: React.Dispatch<React.SetStateAction<string | undefined>>;
    skipFileDownloadList: boolean;
    setSkipFileDownloadList: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const pubnub = usePubNub();
    const context = useContext(GlobalContext);
    const [files, setFiles] = useState<Modules.File[]>();
    const [fileForPreview, setFileForPreview] = useState<Modules.File>();
    const user = store.get('user') as Users.User;
    const actionsChannel = `actions.${CLIENT_ID}_${EVENT_ID}_${user?._id}`;

    useEffect(() => {
        if (moduleId) {
            apiRequester
                .getFiles({ boothId: context.booth?._id!, moduleId })
                .then(files => {
                    setFiles(files);
                    return files;
                })
                .then(files => {
                    if (skipFileDownloadList && files && files.length) setFileForPreview(files[0]);
                });
        }
    }, [context.booth, moduleId, skipFileDownloadList]);

    const AddToBasketButton = ({ item }: { item: Modules.File }) => {
        const [loading, setLoading] = useState(false);
        const addItemToBasket = async (itemId: string) => {
            moduleId &&
                apiRequester
                    .addItemToDownloadBasket({
                        fileId: itemId,
                        boothId: context.booth?._id!,
                        moduleId: moduleId,
                    })
                    .then(() => handleSuccess('Item has been added to your download basket'))
                    .catch(handleError)
                    .finally(() => setLoading(false));
        };
        return (
            <Tooltip title={strings.addToBasket}>
                <Button
                    icon={<FolderAddOutlined />}
                    type="link"
                    shape="circle"
                    onClick={() => addItemToBasket(item._id!)}
                    loading={loading}
                    style={{ marginLeft: '5px', marginRight: '5px' }}
                />
            </Tooltip>
        );
    };

    const triggerVisitorAction = async ({ file, moduleId }: { file: Modules.File; moduleId: string }) => {
        const fileId = file._id!;
        await SendUserActions.downloadFile({
            pubnub,
            channel: actionsChannel,
            boothId: context.booth?._id!,
            moduleId,
            fileId,
        });
        return true;
    };

    const getFileThumbnail = (file: Modules.File) => {
        const thumbnailLink = file.thumbnail?.link;
        if (thumbnailLink) return <img src={thumbnailLink} height={64} style={{ padding: '5px' }} />;
        else {
            const fileName = file.name;
            const extension = fileName?.split('.').pop();
            if (extension?.toLocaleLowerCase() === 'pdf')
                return <img src="/pdf.png" height={64} style={{ padding: '5px' }} />;
            else return <img src="/file.png" height={64} style={{ padding: '5px' }} />;
        }
    };

    const closeModal = () => {
        setModuleId(undefined);
        setSkipFileDownloadList(false);
        setFileForPreview(undefined);
        setFiles([]);
    };

    return (
        <Modal
            visible={moduleId ? true : false}
            onCancel={closeModal}
            footer={null}
            title={
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {fileForPreview?.customName || fileForPreview?.name || fileDownloadsTitle}
                    <Button
                        type="ghost"
                        style={{
                            position: 'absolute',
                            right: '15px',
                            verticalAlign: 'center',
                            height: '32px',
                            width: '32px',
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            border: 0,
                            borderRadius: '10px',
                            color: 'white',
                        }}
                        icon={<CloseOutlined />}
                    />
                </div>
            }
            centered={true}
            style={{ minWidth: fileForPreview || skipFileDownloadList ? '80vw' : undefined }}
            wrapClassName="file-downloads-modal"
            closeIcon={<></>}
            destroyOnClose={true}
            bodyStyle={{
                padding: fileForPreview ? 0 : '15px',
            }}
        >
            {fileForPreview ? (
                fileForPreview.link && fileForPreview.link?.split('?')[0].split('.').slice(-1)[0] === 'pdf' ? (
                    <iframe src={fileForPreview.link} style={{ border: 0, height: '100vh', width: '100%' }} />
                ) : ['doc', 'ppt', 'docx', 'pptx'].includes(fileForPreview.link?.split('.').slice(-1)[0]!) ? (
                    fileForPreview.link && (
                        <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileForPreview.link}`}
                            style={{ border: 0, height: '100vh', width: '100%' }}
                        />
                    )
                ) : (
                    fileForPreview.link && <img src={fileForPreview.link} style={{ width: '100%' }} />
                )
            ) : (
                <List
                    size="large"
                    itemLayout="vertical"
                    dataSource={files}
                    renderItem={(item: Modules.File, index: number) => (
                        <List.Item style={{ width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                {getFileThumbnail(item)}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        flexGrow: 1,
                                        width: '100%',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            flexGrow: 1,
                                            paddingLeft: '15px',
                                            paddingRight: '15px',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {item.customName || item.name}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {moduleId && (
                                            <Tooltip title={strings.download}>
                                                <Button
                                                    href={apiRequester.generateS3DownloadUrl(item.link!)}
                                                    type="link"
                                                    shape="circle"
                                                    icon={<DownloadOutlined />}
                                                    download
                                                    target="_blank"
                                                    onClick={() =>
                                                        triggerVisitorAction({
                                                            file: item,
                                                            moduleId: moduleId,
                                                        })
                                                    }
                                                    style={{ marginLeft: '5px', marginRight: '5px' }}
                                                />
                                            </Tooltip>
                                        )}
                                        <Tooltip title={strings.preview}>
                                            <Button
                                                type="link"
                                                shape="circle"
                                                icon={<EyeOutlined />}
                                                onClick={() => setFileForPreview(item)}
                                                style={{ marginLeft: '5px', marginRight: '5px' }}
                                            />
                                        </Tooltip>

                                        {SHOW_ADD_TO_BASKET_BTN && <AddToBasketButton item={item} />}
                                    </div>
                                </div>
                            </div>
                        </List.Item>
                    )}
                />
            )}
        </Modal>
    );
};

export default FileDownloadsModal;
