import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { SendOutlined } from '@ant-design/icons';
import { Modal, Avatar, Typography, Input, Tag } from 'antd';
import moment from 'moment';
import { default as Pubnub, default as PubNub } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import { animateScroll } from 'react-scroll';
import styled from 'styled-components';

import { GlobalContext } from '../context/GlobalContextProvider';
import { handleError } from '../utility';
import { CHAT_ACTIONS, generateNotifierMessage } from '../utility/NotifierMessages';
import { useBoothCommunication } from '../hooks';

const getNavigatorLanguage = () => (navigator.languages && navigator.languages.length ? navigator.languages[0] : 'en');

const strings = new LocalizedStrings({
    en: {
        boothChat: 'Booth Chat',
        type: 'Type your message',
    },
    de: {
        boothChat: 'Chat',
        type: 'Tippen Sie Ihre Nachricht',
    },
});
const { Text } = Typography;
const { TextArea } = Input;
const PRIMARY_COLOR = process.env.GATSBY_PRIMARY_COLOR!;

const ChatBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
`;

const ChatBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 1.5rem;
`;

const BubbleContainer = styled.div<{ right?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: ${props => (props.right ? 'flex-end' : 'flex-start')};
    width: 100%;
`;

const PrimaryBubble = styled.div`
    background-color: ${PRIMARY_COLOR};
    color: white;
    border-radius: 4px;
    padding: 10px;
    max-width: 75%;
    margin-left: 25%;
`;

const SecondaryBubble = styled.div`
    background-color: white;
    border-radius: 4px;
    padding: 10px;
    max-width: 75%;
`;

const Message = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
`;

const AvatarContainer = styled.div<{ right?: boolean }>`
    position: relative;
    flex-shrink: 0;
    margin-right: ${props => (props.right ? undefined : '12px')};
    margin-left: ${props => (props.right ? '12px' : undefined)};
`;

const TitleContainer = styled.div<{ right?: boolean }>`
    display: flex;
    justify-content: ${props => (props.right ? 'flex-end' : undefined)};
`;

const UserName = styled(Text)`
    padding-right: 8px;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
`;

const Time = styled(Text)`
    padding-right: 8px;
    font-size: 12px;
    line-height: 18px;
    color: #ccc;
`;

const SendContainer = styled.div`
    display: flex;
    align-items: center;
`;

const SendIcon = styled(SendOutlined)`
    font-size: 24px;
    margin-left: 12px;
    margin-right: 0;
    padding-right: 0;
    color: ${PRIMARY_COLOR};
`;

const SecondaryMessage = ({ message, user }: { message: Modules.ChatMessage; user: Users.User }) => {
    const time = new Date(parseInt(message.timetoken.toString()) / 10000);
    return (
        <Message>
            <AvatarContainer>
                <Avatar>{user?.firstName ? user.firstName[0] : 'U'}</Avatar>
            </AvatarContainer>
            <BubbleContainer>
                <TitleContainer>
                    <UserName>
                        {user?.firstName ? user.firstName : ''} {user?.lastName ? user.lastName : ''}
                    </UserName>
                    <Time>{moment(time).locale(getNavigatorLanguage()).fromNow()}</Time>
                </TitleContainer>
                <SecondaryBubble>{message.message}</SecondaryBubble>
            </BubbleContainer>
        </Message>
    );
};

const PrimaryMessage = ({ message, user }: { message: Modules.ChatMessage; user: Users.User }) => {
    const time = new Date(parseInt(message.timetoken.toString()) / 10000);
    return (
        <Message>
            <BubbleContainer right>
                <TitleContainer right>
                    <Time>{moment(new Date(time)).locale(getNavigatorLanguage()).fromNow()}</Time>
                    <UserName>
                        {user?.firstName ? user.firstName : ''} {user?.lastName ? user.lastName : ''}
                    </UserName>
                </TitleContainer>
                <PrimaryBubble>{message.message}</PrimaryBubble>
            </BubbleContainer>
            <AvatarContainer right>
                <Avatar>{user?.firstName ? user.firstName[0] : 'U'}</Avatar>
            </AvatarContainer>
        </Message>
    );
};

export const ChatModal = ({
    showChatModal,
    setShowChatModal,
    operatorsOnline,
}: {
    showChatModal: boolean;
    setShowChatModal: React.Dispatch<React.SetStateAction<boolean>>;
    operatorsOnline: boolean;
}) => {
    const context = useContext(GlobalContext);
    const [messages, setMessages] = useState<
        { message: string; timetoken: number | string; uuid?: string; channel?: string }[]
    >([]);
    const [input, setInput] = useState('');
    const [showModal, setShowModal] = useState(false);
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const boothChat = context.boothChats?.length ? context.boothChats[0] : undefined;
    const chatId = boothChat?._id!;
    const {
        addChannels,
        removeChannels,
        addListener,
        removeListener,
        hereNow,
        objects,
        fetchMessages,
        time,
        publish,
    } = useBoothCommunication();

    useEffect(() => {
        if ((showChatModal || showModal) && !operatorsOnline) {
            handleError(new Error('not online'));
            setShowModal(false);
            setShowChatModal(false);
        }
    }, [showModal, showChatModal]);

    useEffect(() => {
        if (showModal && context.boothChats?.length && showChatModal) {
            setShowModal(true);
        } else if (!showModal && context.boothChats?.length && showChatModal && operatorsOnline) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [showChatModal, operatorsOnline]);

    const refreshUserList = async (userIds: string[]) => {
        const existingIds = Object.keys(context.userList);
        const newIds = userIds.filter(userId => !existingIds.includes(userId));
        const newUserList: { [id: string]: Users.User } = {};

        if (newIds && newIds.length) {
            let filter = ``;
            newIds.map((id, index) => {
                filter += `id=="${id}" ${index < newIds.length - 1 ? '||' : ''}`;
            });

            const allUsers = await objects.getAllUUIDMetadata({
                filter: filter,
                include: { customFields: true, totalCount: true },
            });
            allUsers.data.map(userMeta => {
                const userId = userMeta.id;
                newUserList[userId] = {
                    _id: userId,
                    firstName: userMeta?.name || undefined,
                    roles: [userMeta?.custom?.role ? (userMeta?.custom?.role as Users.Role) : 'visitor'],
                };
            });

            const updatedUserList = JSON.parse(JSON.stringify({ ...context.userList, ...newUserList }));
            context.setUserList(existingUsers => {
                return { ...existingUsers, ...newUserList };
            });

            return updatedUserList;
        }

        return context.userList;
    };

    const fetchChatHistory = async ({ chatId }: { chatId: string }) => {
        setMessages([]);
        const response = await fetchMessages({ channels: [chatId], count: 100 });
        const fetchedMessages: Modules.ChatMessage[] = response.channels[chatId] || [];
        const chatUserIds = new Set<string>();
        fetchedMessages.map(message => {
            const { uuid } = message;
            uuid && uuid !== 'undefined' && chatUserIds.add(uuid);
        });
        await refreshUserList([...chatUserIds]);
        setMessages(fetchedMessages);
        return fetchedMessages;
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef?.current!?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    };

    const messageListener = async (messageEvent: Pubnub.MessageEvent) => {
        const { publisher: publisherUserId } = messageEvent;
        console.log({ publisherUserId });
        await refreshUserList([publisherUserId]);
        if (messageEvent.message && typeof messageEvent.message === 'string') {
            setMessages(messages => [
                ...messages,
                {
                    message: messageEvent.message,
                    timetoken: messageEvent.timetoken,
                    uuid: messageEvent.publisher,
                },
            ]);
            scrollToBottom();
        }
    };

    const listeners: PubNub.ListenerParameters = useMemo(
        () => ({
            message: messageListener,
        }),
        [chatId],
    );

    const updateLastReadTime = async ({ channelId }: { channelId: string }) => {
        const currentTime = (await time()).timetoken;
        await objects.setChannelMetadata({
            channel: channelId,
            data: {
                custom: {
                    lastVisitorMessageTime: currentTime.toString(),
                },
            },
            include: {
                customFields: true,
            },
        });
    };

    useEffect(() => {
        const boothChat = context.boothChats?.length ? context.boothChats[0] : undefined;
        const chatId = boothChat?._id!;

        if (chatId) {
            fetchChatHistory({ chatId })
                .then(() => scrollToBottom())
                .then(() => addListener(listeners))
                .catch(handleError)
                .then(() => addChannels([chatId]));
        }

        // Unsubscribe when unmounted
        return () => {
            removeListener(listeners);
            removeChannels([chatId]);
        };
    }, [context.boothChats]);

    useEffect(() => {
        scrollToBottom();
    }, [showChatModal, showModal]);

    const getUserFromId = (userId: string) => {
        return context.userList[userId];
    };

    const sendMessage = async () => {
        try {
            if (!input) return;
            const boothChat = context.boothChats![0];
            const chatId = boothChat?._id!;
            await publish({
                channel: chatId,
                message: input,
            });
            setInput('');
            await updateLastReadTime({ channelId: chatId });
            await publish({
                channel: `booth-operators.${context.booth?._id!}`,
                message: generateNotifierMessage.chat({
                    booth: {
                        id: context.booth?._id!,
                    },
                    action: CHAT_ACTIONS.NEW_MESSAGE,
                    sender: {
                        id: context.user?._id!,
                        firstName: context.user?.firstName,
                        lastName: context.user?.lastName,
                    },
                }),
            });
        } catch (err) {
            handleError(err);
        }
    };

    const keyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            sendMessage();
        }
    };

    const lastOneDayMessageFilter = (message: {
        message: string;
        timetoken: number | string;
        uuid?: string | undefined;
        channel?: string | undefined;
    }) => {
        const messageTime = new Date(parseInt(message.timetoken.toString()) / 10000).getTime();
        const now = new Date().getTime();
        const oneDayAgoInMs = 24 * 60 * 60 * 1000;
        if (now - messageTime <= oneDayAgoInMs) return true;
        else return false;
    };

    return (
        <Modal
            visible={showModal ? true : false}
            footer={null}
            title={
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    {operatorsOnline ? <Tag color="#87d068">ONLINE</Tag> : <Tag color="#f50">OFFLINE</Tag>}
                    <Typography.Title level={5}>{strings.boothChat}</Typography.Title>
                </div>
            }
            onCancel={() => setShowChatModal(false)}
            bodyStyle={{ height: '60vh', backgroundColor: '#f6f6fc' }}
        >
            <ChatBoxContainer>
                <ChatBox id="chat-box">
                    <>
                        {messages.filter(lastOneDayMessageFilter).map((messageEvent, messageIndex) => {
                            const messageSenderUserId = messageEvent.uuid;
                            const messageBelongsToLoggedInUser = messageSenderUserId === context.user?._id!;
                            const user = getUserFromId(messageEvent.uuid!);
                            return messageBelongsToLoggedInUser || !user ? (
                                <>
                                    <PrimaryMessage
                                        key={`message-${messageIndex}`}
                                        message={messageEvent as Modules.ChatMessage}
                                        user={user}
                                    />
                                </>
                            ) : (
                                <>
                                    <SecondaryMessage
                                        key={`message-${messageIndex}`}
                                        message={messageEvent as Modules.ChatMessage}
                                        user={user}
                                    />
                                </>
                            );
                        })}
                        <div ref={messagesEndRef} />
                    </>
                </ChatBox>

                <SendContainer>
                    <TextArea
                        placeholder={strings.type}
                        value={input}
                        onChange={e => setInput(e.target.value)}
                        onKeyDown={keyPress}
                    />
                    <SendIcon onClick={sendMessage} />
                </SendContainer>
            </ChatBoxContainer>
        </Modal>
    );
};

export default ChatModal;
